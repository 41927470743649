import { createAsyncThunk } from '@reduxjs/toolkit';
import ReportBuilderService from 'services/report-builder.service';
import { ITemplateList, ReportBuilderStatus } from 'core/models/report-builder/report-builder';
import { IFilterConfig } from 'core/models/report-response';
import { IGlobalState } from 'core/models/redux';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import { IPanelDataResponse } from 'core/models/dashboard';
import { FeatureContext, PageContext } from 'core/constants/common';
import SieraResponse from 'core/models/siera-response';
import { statusCodes } from 'core/constants/report';
import { setSettingsError } from 'redux-v2/settings-store/settings-store.reducer';
import { isValidObjectAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { getMeasureIdentifiersPayload } from 'components/feature/ReportBuilderV2/ReportBuilderDetail/DrilldownTabContent/drilldown.util';
import { ObjModel } from 'core/models';
import { getCreatedByListForReports } from 'redux-v2/customAnalytics/custom-analytics.requests';
import {
  DrilldownColumnConfig,
  IFilterDataLoadRequestPayload, MeasureIdentifier, MeasureIdentifierPayload, ReportBuilderActions,
  IRawTemplatePayload,
  ISaveReportPayload,
} from './report-builder-store.state';
import {
  getRawTemplateDetailsFailure,
  getRawTemplateDetailsRequest,
  getRawTemplateDetailsSuccess,
  initDrilldownMapping,
  reportBuilderInitialFailureState, reportBuilderInitialLoadState, reportBuilderInitialSuccessState,
  reportBuilderPreviewFailureState, reportBuilderPreviewLoadState, reportBuilderPreviewSuccessState,
  reportBuilderSetTempId,
  reportBuilderUpdateSelectedTemplate,
} from './report-builder-store.reducer';
import {
  getSaveReportConfigPayload, selectedReportTemplate, getInitialDrilldownConfigInfo, getUpdatedDrilldownCols,
} from './report-builder-store.utils';
import { defaultEntitySelector } from './report-builder-store.selectors';

export const getReportTemplateList = createAsyncThunk(ReportBuilderActions.GET_TEMPLATE_LIST,
  async () => new ReportBuilderService().getReportTemplateList());

export const saveReportConfig = createAsyncThunk(ReportBuilderActions.SAVE_REPORT_CONFIG,
  async (payload:ISaveReportPayload, { rejectWithValue, dispatch }) => {
    try {
      return await new ReportBuilderService().saveReportConfig(payload.data, payload.params);
    } catch (err: any) {
      if (err?.status === statusCodes.Forbidden) {
        dispatch(getCreatedByListForReports(payload.data?.ReportConfigId));
      }
      return rejectWithValue(err);
    }
  });

export const getReportBuilderDynamicFilters = createAsyncThunk(ReportBuilderActions.GET_ADDITIONAL_FILTERS,
  async (reportId:string) => new ReportBuilderService().getDynamicFilters(reportId));

export const getDynamicDimensions = createAsyncThunk(ReportBuilderActions.GET_ADDITIONAL_DIMENSIONS,
  async (reportId:string) => new ReportBuilderService().getDynamicDimensions(reportId));

export const getDynamicMeasures = createAsyncThunk(ReportBuilderActions.GET_ADDITIONAL_MEASURES,
  async (reportId:string) => new ReportBuilderService().getDynamicMeasures(reportId));

export const getFieldsRequest = createAsyncThunk(ReportBuilderActions.GET_FIELDS,
  async (reportId:string) => new ReportBuilderService().getFieldsRequest(reportId));

export const getReportRawConfig = createAsyncThunk(ReportBuilderActions.GET_REPORT_RAW_CONFIG_REQUEST,
  async (reportId:string) => new ReportBuilderService().getReportRawConfig({ reportId }));

export const getReportRenderConfig = createAsyncThunk(ReportBuilderActions.GET_REPORT_RENDER_CONFIG_REQUEST,
  async (reportId:string) => new ReportBuilderService().getReportRenderConfig({ reportId }));

export const getReportBuilderFiltersLoad = createAsyncThunk(ReportBuilderActions.FILTER_DATA_LOAD_REQUEST,
  async (filterpayload: IFilterDataLoadRequestPayload, { getState }) => {
    const {
      filterId,
      variables,
      filterValue,
      isLazyLoad,
    } = filterpayload;
    const { reportBuilder: { filterBuilder: { filterConfig, appliedFilters }, updatedReportBuilderInfo: { selectedTemplate: { ReportConfig } } } } = getState() as IGlobalState;

    let featureContextInfo = ReportConfig?.FeatureContext;
    if (Object.prototype.hasOwnProperty.call(ReportConfig, 'FeatureContext') && !ReportConfig?.FeatureContext) {
      featureContextInfo = FeatureContext.Sales;
    }
    const filter = filterConfig.find(
      (item: IFilterConfig) => item.ID === filterId,
    );
    const value = filter.LinkedTo
      ? appliedFilters[filter.LinkedTo].FilterResponse
      : null;

    const filterPayload = new FilterFetchUtility(
      variables,
    ).getUserAdditionalFilterPayload(appliedFilters, filterConfig);

    return new FilterFetchUtility(variables)
      .GetSingleFilterData(
        filter,
        value,
        isLazyLoad ? filterValue : appliedFilters[filterId].FilterResponse,
        appliedFilters[filterId],
        filterPayload,
        featureContextInfo,
      );
  });

export const initialSaveTemplateConfig = createAsyncThunk(ReportBuilderActions.INITIAL_SAVE_TEMPLATE,
  async ({ rawTemplateId, namespace, joins }: IRawTemplatePayload, { getState, dispatch }) => {
    const { auth: { variables } } = getState() as IGlobalState;
    dispatch(getRawTemplateDetailsRequest());
    new ReportBuilderService()
      .getRawTemplateDetails(rawTemplateId, namespace, joins)
      .then((response: ITemplateList) => {
        dispatch(getRawTemplateDetailsSuccess());
        dispatch(reportBuilderUpdateSelectedTemplate(response));

        dispatch(reportBuilderInitialLoadState());
        const reportConfig = selectedReportTemplate(response);

        const updatedReportBuilderPayload = {
          ...reportConfig,
          Status: ReportBuilderStatus.DRAFT,
        };
        if (Object.prototype.hasOwnProperty.call(updatedReportBuilderPayload, 'FeatureContext') && !updatedReportBuilderPayload.FeatureContext) {
          updatedReportBuilderPayload.FeatureContext = FeatureContext.Sales;
        }
        new ReportBuilderService().saveReportConfig(updatedReportBuilderPayload, { PageContext: PageContext.BUILDER }).then((res: SieraResponse<string>) => {
          dispatch(reportBuilderInitialSuccessState({ reportId: res.data, variables }));
          dispatch(getReportBuilderPreviewData({ reportId: res.data, isInitialLoad: true }));
        }).catch(() => dispatch(reportBuilderInitialFailureState()));
      })
      .catch(() => {
        dispatch(getRawTemplateDetailsFailure());
      });
  });

export const getReportBuilderPreviewData = createAsyncThunk(ReportBuilderActions.GET_PREVIEW_DATA,
  async (params:{reportId: string, isInitialLoad?:boolean, initializeDrilldown?:boolean}, { getState, dispatch }) => {
    dispatch(reportBuilderPreviewLoadState());
    const { reportBuilder } = getState() as IGlobalState;
    const { reportEditInfo, updatedReportBuilderInfo: { reportBuilderSavePayload } } = reportBuilder;
    const savePayloadReportConfig = getSaveReportConfigPayload(reportBuilder);

    const { reportId, isInitialLoad, initializeDrilldown } = params;

    if (isInitialLoad) { // initial save config is already done, directly we trigger preview request
      new ReportBuilderService()
        .getDynamicDataById(reportId, reportBuilderSavePayload?.FeatureContext || FeatureContext.Sales)
        .then((res: IPanelDataResponse) => {
          dispatch(reportBuilderPreviewSuccessState({ res, isInitialLoad }));
          dispatch(initDrilldownMapping());
        })
        .catch(() => {
          dispatch(reportBuilderPreviewFailureState());
          dispatch(initDrilldownMapping()); // in case fetch-report-v2 breaks, drilldown should get initialised with raw-template data
        });
    } else {
      new ReportBuilderService() // default preview click, save config followed by preview request
        .saveReportConfig({
          ...savePayloadReportConfig,
          Status: ReportBuilderStatus.DRAFT,
          ReportConfigId: reportEditInfo?.isEditing ? reportEditInfo?.temporaryReportId : savePayloadReportConfig?.ReportConfigId,
        }, { PageContext: reportEditInfo?.isEditing ? PageContext.EDIT : PageContext.BUILDER })
        .then((response: SieraResponse<string>) => {
          if (reportEditInfo?.isEditing) {
            dispatch(reportBuilderSetTempId(response.data));
          }
          new ReportBuilderService()
            .getDynamicDataById(reportEditInfo?.isEditing ? response.data : reportId, reportBuilderSavePayload?.FeatureContext || FeatureContext.Sales)
            .then((res: IPanelDataResponse) => {
              if (initializeDrilldown) {
                dispatch(initDrilldownMapping());
                if (reportEditInfo?.isEditing) {
                  dispatch(getDrilldownConfig(reportId));
                }
              }
              dispatch(reportBuilderPreviewSuccessState({ res }));
            })
            .catch(() => dispatch(reportBuilderPreviewFailureState()));
        })
        .catch((err) => {
          if ((err as Response)?.status === statusCodes.Unprocessable) dispatch(setSettingsError(true));
          dispatch(reportBuilderPreviewFailureState());
        });
    }
  });
export const getAdditionalDrilldownColumnsRequest = createAsyncThunk(ReportBuilderActions.GET_ADDITIONAL_DRILLDOWN_COLUMN,
  async ({ reportId, data }:{reportId: string, data: MeasureIdentifier, groupId: string}) => new ReportBuilderService().getAdditionalDrilldownColumns(reportId, data));

export const getDefaultDrilldownColumnsRequest = createAsyncThunk(ReportBuilderActions.GET_DEFAULT_DRILLDOWN_COLUMN,
  async ({ reportId, data }:{reportId: string, data: MeasureIdentifierPayload}) => new ReportBuilderService().getDefaultDrilldownColumns(reportId, data));

export const getDrilldownConfig = createAsyncThunk(ReportBuilderActions.GET_DRILLDOWN_CONFIG,
  async (reportId: string) => new ReportBuilderService().getDrilldownConfig(reportId));

export const saveDrilldownColumnConfig = createAsyncThunk(ReportBuilderActions.SAVE_DRILLDOWN_CONFIG,
  async ({ reportId, data }: { reportId: string, data: DrilldownColumnConfig }) => new ReportBuilderService().saveDrilldownColumnConfig(reportId, data));

export const saveDrilldownColsWithSaveReportConfig = createAsyncThunk(ReportBuilderActions.SAVE_DRILLDOWNCOLS_WITH_REPORT_CONFIG,
  async (_, { getState, dispatch }) => {
    const state = getState() as IGlobalState;
    const { reportBuilder: { updatedReportBuilderInfo: { drilldownConfigInfo, appliedMeasures }, assortedInfo: { reportId }, reportBuilderInfo: { fields } } } = state;
    if (drilldownConfigInfo?.enableDrilldown) {
      const defaultEntity = defaultEntitySelector(state);
      const accordKeys = isValidObjectAndNotEmpty(drilldownConfigInfo.drilldownConfigMapping) ? Object.keys(drilldownConfigInfo.drilldownConfigMapping) : [];
      const columnKeys = isValidObjectAndNotEmpty(drilldownConfigInfo.drilldownColumnConfig) ? Object.keys(drilldownConfigInfo.drilldownColumnConfig) : [];
      const nonExistColumns = accordKeys?.filter((key) => !columnKeys.includes(key));
      const isMeasureWithoutDrilldownCols = accordKeys?.every((key) => drilldownConfigInfo.drilldownConfigMapping[key]?.length > 0);
      if (nonExistColumns?.length && isMeasureWithoutDrilldownCols) {
        const MeasureIdentifiersPayload = getMeasureIdentifiersPayload(nonExistColumns, appliedMeasures, drilldownConfigInfo?.drilldownConfigMapping, fields, defaultEntity);
        new ReportBuilderService().getDefaultDrilldownColumns(reportId, { DefaultDrillDownColumnsInput: MeasureIdentifiersPayload })
          .then((res: ObjModel.Obj) => {
            const drilldownColConfig = getInitialDrilldownConfigInfo(res, drilldownConfigInfo);
            const updatedData = getUpdatedDrilldownCols(drilldownColConfig);
            dispatch(saveDrilldownColumnConfig({ reportId, data: updatedData }));
          }).catch((e) => console.log(e)); // handle error state
      } else {
        const updatedData = getUpdatedDrilldownCols(drilldownConfigInfo.drilldownColumnConfig);
        dispatch(saveDrilldownColumnConfig({ reportId, data: updatedData }));
      }
    }
  });
